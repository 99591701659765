<template>
  <e-sidebar
    id="sidebar-form-tiered-price"
    :title="isEditingMode ? $t('Editar preço escalonado') : $t('Adicionar preço escalonado')"
    :show="showSidebar"
    :fetching="fetching"
    :saving="saving"
    width="600px"
    @save="onSaveItem"
    @hidden="hide"
  >
    <template #content>
      <FormulateForm
        ref="itemSidebarForm"
        name="itemSidebarForm"
      >
        <b-row>
          <b-col md="12">
            <e-store-combo
              id="filters-store"
              v-model="itemForm.storeId"
              :only-active-options="true"
              :required="false"
              :disabled="isEditingMode"
              :placeholder="$t('Todas')"
            />
          </b-col>
          <b-col md="12">
            <FormulateInput
              id="tiered_price_sidebar-price-table"
              v-model="itemForm.priceTableId"
              type="vue-select"
              class="required"
              validation="required"
              :disabled="isEditingMode"
              :label="$t('Tabela de preço')"
              :placeholder="$t('Selecione')"
              :options="priceTableOptions"
            />
          </b-col>
        </b-row>
        <FormulateInput
          v-slot="{ index }"
          ref="priceGroup"
          v-model="localTieredPrices"
          name="tieredPrices"
          type="group"
          :repeatable="true"
          :add-label="$t('Adicionar faixa')"
          @repeatableRemoved="handleRemove"
        >
          <b-row>
            <b-col md="4">
              <FormulateInput
                :id="`tiered_price_sidebar-quantity-from-${index}`"
                name="quantityFrom"
                type="text-number"
                :disabled="index > 0"
                :value="getQuantityFrom(index)"
                class="required"
                :label="$t('Quantidade inicial')"
                error-behavior="submit"
                validation="required"
              />
            </b-col>
            <b-col md="4">
              <FormulateInput
                :id="`tiered_price_sidebar-quantity-to-${index}`"
                name="quantityTo"
                type="text-number"
                :label="$t('Quantidade final')"
                @input="updateNextQuantityFrom(index)"
              />
            </b-col>
            <b-col md="4">
              <FormulateInput
                :id="`tiered_price_sidebar-price-${index}`"
                name="price"
                type="text-number"
                currency="R$"
                :precision="2"
                validation="required|min:0.01"
                class="required"
                :label="$t('Preço')"
                error-behavior="submit"
                @input="validatePrice(index)"
              />
            </b-col>
          </b-row>
        </FormulateInput>
      </FormulateForm>
    </template>
  </e-sidebar>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { BRow, BCol } from 'bootstrap-vue'
import { ESidebar, EStoreCombo } from '@/views/components'
import { authorizationRules, discountTypes, formulateHelper, stringUtils } from '@/mixins'

const getInitialTieredPriceForm = () => ({
  id: null,
  quantityFrom: 0,
  quantityTo: null,
  price: null,
})

const getInitialItemForm = () => ({
  storeId: null,
  priceTableId: null,
})

const SAFE_JAVASCRIPT_MAX_INTEGER_VALUE = Number.MAX_SAFE_INTEGER
const SAFE_MAX_INPUT_VALUE = 99999

export default {
  components: { BRow, BCol, ESidebar, EStoreCombo },

  mixins: [discountTypes, formulateHelper, authorizationRules, stringUtils],

  props: {
    storeId: {
      type: [String, Number],
      default: null,
    },
    priceTableId: {
      type: [String, Number],
      default: null,
    }
  },

  data() {
    return {
      itemForm: getInitialItemForm(),
      showSidebar: false,
      fetching: false,
      saving: false,
      saved: false,
      isEditingMode: false,
      localTieredPrices: [getInitialTieredPriceForm()],
    }
  },

  computed: {
    ...mapGetters('pages/catalog/products/productMaintain', ['priceTableOptions']),
    ...mapState('pages/catalog/products/productMaintain', ['priceTables']),
    ...mapState('pages/catalog/products/productMaintain', {
      tieredPricesStore: 'tieredPrices'
    }),
  },

  methods: {
    ...mapActions('pages/catalog/products/productMaintain', ['setTieredPrice']),
    async show(item) {
      this.cleanSidebar()
      this.showSidebar = true
      this.isEditingMode = false
      this.saved = false

      if (item) {
        this.isEditingMode = true
        this.itemForm = {
          ...item,
          priceTableId: this.getStringOrNull(item.priceTableId)
        }
        this.localTieredPrices = JSON.parse(JSON.stringify(item.prices || [getInitialTieredPriceForm()]))
      }
    },

    hide() {
      this.cleanSidebar()
      this.showSidebar = false
    },

    cleanSidebar() {
      this.itemForm = getInitialItemForm()
      this.localTieredPrices = [getInitialTieredPriceForm()]
      this.$formulate.reset('itemSidebarForm')
    },

    async onSaveItem() {
      try {
        this.saving = true
        this.$refs.itemSidebarForm.showErrors()

        if (this.$refs.itemSidebarForm.hasErrors) {
          this.showInvalidDataMessage()
          return
        }

        const errors = this.localTieredPrices.map((_, index) => {
          const result = this.validatePrice(index)
          if (!result) return true

          return false
        })

        if (errors.includes(true)) return

        const buildedTieredPrices = this.buildTieredPrices()
        const problematicPriceRanges = this.getProblematicPriceRanges(buildedTieredPrices)

        if (problematicPriceRanges.length > 0) {
          this.showWarningForProblematicRanges(problematicPriceRanges)
          return
        }

        this.setTieredPrice(buildedTieredPrices)

        this.saved = true
        this.hide()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    buildTieredPrices() {
      return this.applySafeNumberConfigurationTo(this.localTieredPrices.map((tieredPrice, index) => {
        const { quantityFrom, quantityTo, price, id, isDeleted = false } = tieredPrice || {}

        const isUnlimitedBeforeLast = index !== (this.localTieredPrices.length - 1)
        const isValidToValidateRange = quantityTo !== null && quantityTo !== undefined

        return {
          id,
          storeId: this.getStringOrNull(this.itemForm.storeId),
          priceTableId: this.getStringOrNull(this.itemForm.priceTableId),
          quantityFrom,
          quantityTo,
          price,
          problematic: (isValidToValidateRange || isUnlimitedBeforeLast) ? quantityFrom >= (quantityTo ?? 0) : false,
          isDeleted
        }
      }))
    },

    getProblematicPriceRanges(_tieredPrices) {
      return _tieredPrices
        .map((price, index) => (price.problematic ? index : null))
        .filter(index => index !== null)
    },

    showWarningForProblematicRanges(problematicPriceRanges) {
      const warningMessage = this.buildFormattedWaningMessage(problematicPriceRanges)
      this.showWarning({ message: this.$t(warningMessage) })
    },

    buildFormattedWaningMessage(problematicPriceRanges) {
      const warningMessage = this.$tc(
        'TIERED_PRICE.VALIDATION.INITIAL_QUANTITY_GT_OR_EQ_FINAL',
        problematicPriceRanges.length,
        { numbers: this.formatWarningRange(problematicPriceRanges) }
      )

      return warningMessage
    },

    formatWarningRange(ranges) {
      if (ranges.length === 1) return ranges[0] + 1

      const allButLast = ranges.slice(0, -1).map(range => range + 1).join(', ')
      const last = ranges[ranges.length - 1] + 1

      return `${allButLast} e ${last}`
    },

    getQuantityFrom(index) {
      if (index === undefined) return undefined

      if (index === 0) return 1
      return (this.localTieredPrices[index - 1]?.quantityTo ?? 0) + 1
    },

    updateNextQuantityFrom(index) {
      if (index < this.localTieredPrices.length - 1) {
        this.localTieredPrices[index + 1].quantityFrom = this.localTieredPrices[index].quantityTo + 1
      }
    },

    validatePrice(index) {
      if (index <= 0) return true

      const previousPrice = this.localTieredPrices[index - 1]?.price
      const currentPrice = this.localTieredPrices[index]?.price

      if (currentPrice <= previousPrice) return true

      // Se o preço atual for maior que o anterior, defina como o mesmo valor do anterior
      this.localTieredPrices[index].price = previousPrice
      this.showWarning({ message: 'O preço não pode ser maior que o do item anterior.' })

      return false
    },
    handleRemove(item) {
      item.forEach((_, index) => {
        this.updateNextQuantityFrom(index)
      })
    },

    applySafeNumberConfigurationTo(arrayOfObject) {
      const safeArrayOfObject = []

      arrayOfObject.forEach(object => {
        const updatedObject = {
          ...object,
          quantityTo: this.updateUnsafeNumberForSafeNumber(object.quantityTo),
          quantityFrom: this.updateUnsafeNumberForSafeNumber(object.quantityFrom),
          safe: true
        }
        safeArrayOfObject.push(updatedObject)
      })

      return safeArrayOfObject
    },

    updateUnsafeNumberForSafeNumber(number) {
      let numberToSafe = number

      if (!Number.isSafeInteger(number)) numberToSafe = SAFE_JAVASCRIPT_MAX_INTEGER_VALUE || SAFE_MAX_INPUT_VALUE
      if (numberToSafe > SAFE_MAX_INPUT_VALUE) numberToSafe = SAFE_MAX_INPUT_VALUE
      if (number === undefined || number === null) numberToSafe = null

      return numberToSafe
    }
  },
}
</script>

<style lang="scss" scoped>
.text-bold {
  font-weight: 800;
}
</style>
