<template>
  <div>
    <b-table
      ref="priceTable"
      :key="tableKey"
      class="table-no-minheight bordered"
      show-empty
      :empty-text="getEmptyTableMessage($tc('TIERED_PRICE.NAME'), 'male')"
      responsive
      striped
      :fields="fields"
      :items="tieredPricesFormatted.filter((p) => !p.isDeleted)"
    >
      <template #cell(action)="row">
        <e-grid-actions
          v-if="!isReadOnly"
          :show-update="true"
          :show-delete="true"
          @delete="deletePrice(row)"
          @update="updatePrice(row)"
        />
        <span v-if="row.item.storeId === -1 || isReadOnly">-</span>
      </template>
      <template #cell(prices)="row">
        <span v-html="row.value" />
      </template>
    </b-table>
    <b-row v-if="!isReadOnly">
      <b-col
        md="12"
        class="text-right"
      >
        <e-button
          class="my-1"
          variant="primary"
          icon="plus-circle"
          :text="$t('Adicionar preço escalonado')"
          @click="addTieredPrice"
        />
      </b-col>
    </b-row>
    <tiered-price-sidebar ref="tieredPriceSidebar" />
  </div>
</template>

<script>
import { BTable, BRow, BCol } from 'bootstrap-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { EGridActions, EButton } from '@/views/components'
import TieredPriceSidebar from './TieredPriceSidebar.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    EGridActions,
    EButton,
    TieredPriceSidebar,
  },

  mixins: [],

  props: {
    skuIndex: {
      type: Number,
      default: 0,
    },
    requirePrice: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      tableKey: 0,
      variationFormData: {},
      showSidebar: false,
      saving: false,
      isUpdate: false,
      updateIndex: null,
      variantsFields: [],
      localSkuPrices: [],
      showAddTieredPrice: false,
      storeToAdd: '',
    }
  },

  computed: {
    ...mapState('pages/catalog/products/productMaintain', [
      'product',
      'tieredPrices',
      'isReadOnly',
    ]),
    ...mapGetters('pages/catalog/products/productMaintain', [
      'priceTableName',
      'tieredPricesFormatted',
    ]),
    ...mapGetters('app', ['storeOptions', 'storeById']),
    fixedFields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          thStyle: 'width: 100px;',
          tdClass: 'text-center',
        },
        {
          label: this.$t('Loja'),
          key: 'storeId',
          formatter: value =>
            this.$options.filters.storeName(this.storeById(Number(value)), this.$t('Geral')),
        },
        {
          label: this.$t('Tabela de Preço'),
          key: 'priceTableId',
          formatter: value => this.priceTableName(value) ?? '-',
        },
        {
          label: this.$t('Preços'),
          key: 'prices',
          formatter: value =>
            value
              .map(
                price =>
                  `${this.$t('De')} <b>${price.quantityFrom}</b> ${this.$t('até')} <b>${price.quantityTo ? price.quantityTo : this.$t('ilimitado')
                  }</b>: <b>${this.$options.filters.currency(price.price)}</b>`
              )
              .join(' | '),
        },
      ]
    },
    fields() {
      return [...this.fixedFields]
    },
  },

  watch: {},

  mounted() { },

  methods: {
    ...mapActions('pages/catalog/products/productMaintain', ['deleteTieredPrice']),
    async deletePrice(row) {
      if (await this.confirm({ text: this.$t('Confirma a exclusão deste preço?') })) {
        this.deleteTieredPrice({ storeId: row.item.storeId, priceTableId: row.item.priceTableId })
      }
    },
    updatePrice(row) {
      this.$refs.tieredPriceSidebar.show(row.item)
    },
    getNewPrices() {
      const newPrices = []
      this.localSkuPrices.forEach(pt => {
        Object.keys(pt)
          .filter(key => key.startsWith('priceTable_') && key.endsWith('changed'))
          .forEach(key => {
            const price = pt[key.replace('_changed', '')]
            newPrices.push({
              priceTableId: parseInt(key.replace('priceTable_', '').replace('_changed', ''), 10),
              skuId: this.product.skus[this.skuIndex].id,
              storeId: pt.storeId > 0 ? parseInt(pt.storeId, 10) : undefined,
              price: (price === '' ? null : price) ?? 0,
            })
          })
      })
      return newPrices
    },
    addTieredPrice() {
      this.$refs.tieredPriceSidebar.show()
    },
    confirmAddStorePrice() {
      if (!this.$refs.formAddStore.isValid) {
        this.showInvalidDataMessage()
        return
      }
      const skuPrice = {
        storeId: this.storeToAdd,
        storeName: this.storeOptions.find(store => store.value === this.storeToAdd).tradingName,
      }
      this.priceTables.forEach(pt => {
        skuPrice[`priceTable_${pt.id}`] = 0
      })
      this.localSkuPrices.push(skuPrice)
      this.showAddTieredPrice = false
      this.storeToAdd = undefined
    },
  },
}
</script>
